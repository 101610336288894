<script>
import TextService from "@/services/text";


export default {
  name: 'TagDropdown',
  components: {
  },
  props: {
      value: Array,
      availableOptions: {
        type: Array,
        default() {
            return []
        } //[ {text, value} ]
      }
  },
  data() {
    return {
        searchText: null
    }
  },
  methods: {
    onSearchEnterKeyup({ addTag }) {
        // pressing enter on search field will enter the first result in the dropdown
        if (this.searchedOptions.length > 0) {
            addTag(this.searchedOptions[0].value)
            this.searchText = ''
        }
    },
    onOptionClick({ selected, addTag }) {
        addTag(selected)
        this.searchText = ''
    },
    capitalizeFirstChar: TextService.capitalizeFirstChar
  },
  computed: {
    searchedOptions() {

        const searchKey = this.searchText ? this.searchText.trim().toLowerCase() : null
        // const options = this.option.subtype.map(opt => opt.value).filter(opt => this.form.subtype.indexOf(opt) === -1)
        let searchedOptions = this.availableOptions.filter(opt => this.value.indexOf(opt.value) === -1)

        if (searchKey) {
            // Show only options that match criteria
            return searchedOptions.filter(opt => opt.value.toLowerCase().indexOf(searchKey) > -1);
        }

        return searchedOptions
    }
  }
}
</script>

<template>
    <b-form-tags
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        no-outer-focus
        class="mb-2 p-0"
        style="border-radius: 6px; width: 100%"
    >
        
        <template v-slot="{ tags, addTag, removeTag }">
            <b-input-group>
                <template #prepend>
                    <b-dropdown
                        class="tag-dropdown mr-1"
                        menu-class="tag-dropdown__menu"
                        no-caret
                        @shown="$refs.searchText.$el.focus()"
                    >
                        <template #button-content>
                            <b-icon icon="plus" shift-v="-1" scale="1.3" />
                        </template>

                        <b-dropdown-form
                            @submit.stop.prevent="() => {}"
                        >
                            <b-form-input
                                ref="searchText"
                                v-model="searchText"
                                placeholder="Search"
                                @keyup.enter.stop.prevent="onSearchEnterKeyup({ addTag })"
                            >
                            </b-form-input>
                        </b-dropdown-form>
                        <b-dropdown-divider></b-dropdown-divider>
                        <div class="tag-dropdown__menu__list">
                             <b-dropdown-item-button
                                v-for="(option, index) in searchedOptions"
                                :key=index
                                @click="onOptionClick({ selected: option.value, addTag })"
                            >
                                {{ option.text }}
                            </b-dropdown-item-button>
                            <b-dropdown-text
                                v-if="searchedOptions.length === 0"
                            >
                                Not found
                            </b-dropdown-text>
                        </div>
                       
                    </b-dropdown>
                </template>

                
                <ul
                    v-if="tags.length > 0"
                    class="tag__container"
                >
                    <li
                        v-for="tag in tags"
                        :key="tag"
                    >
                        <div
                            class="tag animate__animated animate__fadeInLeft animate__faster"
                            :title="tag"
                        >
                            {{ availableOptions.filter(o => o.value === tag)[0].text }}
                            <b-icon 
                                icon="x"
                                scale="1.2"
                                class="icon--close"
                                @click="removeTag(tag)"
                            />
                        </div>
                    </li>
                </ul>
            </b-input-group>
        </template>
    </b-form-tags>
</template>

<style lang="scss">
    .tag-dropdown {
        height: 100%;
        &__menu {
            width: 220px;
           

            &__list {
                max-height: 330px;
                overflow-y: auto;
                scrollbar-width: thin;
                scrollbar-color: #0687f0;
            }
            

            &__list::-webkit-scrollbar {
                width: 10px;
            }

            &__list::-webkit-scrollbar-track {
                background-color: #2e2e2e;
                border-radius: 10px;
            }

            &__list::-webkit-scrollbar-thumb {
                border-radius: 10px;
                border: 2px solid transparent;
                background-clip: content-box;
                background-color: #202020;
            }
        }

    }
    
    .tag__container {
        list-style: none;
        padding-left: 0;
        margin: 3px;
        width: calc(100% - 60px);

        li {
            display: inline-block;
            margin: 2px 3px;
            .tag {
                background: #444;
                color: white;
                font-size: 0.9em;
                padding: 3px 6px 3px 8px;
                border-radius: 3px;
            }
            .icon--close {
                cursor:pointer;
                vertical-align: text-bottom;
                width: 1.2em;
                height: 1.2em;
                margin-left: 1px;
                border-radius: 2px;
            }
            .icon--close:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
    
</style>
