<script>
import {debounce} from 'lodash';
import TextService from "@/services/text";
import TagDropdown from "@/components/TagDropdown.vue";

import {SlideXLeftTransition, SlideYUpTransition } from 'vue2-transitions'

export default {
	name: "AdvancedSearch",
	components: {
        TagDropdown,

        SlideXLeftTransition,
        SlideYUpTransition,
	},
	data() {
		return {
            query: null, // query is what actually get sent to search
            queryString: '',

            // form is for storing input data. Very similar to query except it lists all available attributes
            form: {
                name: null,
                effect: null,

                flavor: null,
                type: [],
                type_logic: 'OR',
                subtype: [],
                subtype_logic: 'OR',
                class: [],
                class_logic: 'OR',
                element: [],
                rarity: [],

                prefix: null,
                collector_number: null,

                stats: null,

                legality_format: null,
                legality_limit: null,

                illustrator: null
            },

            // for storing local search variable in this form only, never pass to server
            internalSearch: {
                subtype: null,
            },

            // for storing stat in object form
            stats: [],
            stat: {
                name: null,
                nameText: null,
                operator: null,
                operatorSymbol: null,
                operatorText: null,
                value: null
            },
            
            option: null,

            searchComments: [],

            tippyConfig: {
                arrow: true,
                placement: 'bottom-start',
                theme: 'grey',
            }
		};
	},
    methods: {
        init() {
            this.option = this.$searchOptions
            this.updateSearch()
        },
        onInputKeyupEnter() {
            // when pressing enter key on input field, commit search if there are entered parameters
            // wait for updateSearch to execute first to include all params before searching
            const vm = this
            setTimeout(function () {
                if (vm.queryString.length > 0) {
                    vm.search()
                }
            }, 300)
        },
        search () {
            this.$router.push({ name: 'SearchResult',  query: this.query  })
        },
        checkStat(stat) {

            return stat.name &&
                stat.operator &&
                (
                    !this.showNumericalInputField(stat.operatorText)
                    ||
                    (
                        (stat.value || stat.value === 0) &&
                        Number.isInteger(Number(stat.value))
                    )
                )
        },
        checkAddFirstStat() {
            if (this.firstStatAllFilled) {
                this.addStat()
                return true
            }

            return false
        },
        addStat() {
            this.stats.push(this.stat)
            this.stat = Object.assign({}, this.stat,
                {
                    name: null,
                    nameText: null,
                    operator: null,
                    operatorSymbol: null,
                    operatorText: null,
                    value: null
                }
            )
            this.syncStat()
        },
        deleteStat(index) {
            this.stats.splice(index, 1)
            this.syncStat()
        },
        syncStat() {
            // sync stats with query.stats query
            this.form.stats = TextService.getStatQuery(this.stats)
            this.updateSearch()
        },
        validateInteger(value, mode) {
            if (value === null || value === '') return null
            const isInteger = Number.isInteger(Number(value))
            
            //return null instead of true if the mode is 'errorOnly'
            if (mode === 'errorOnly' && isInteger) {
                return null
            }

            return isInteger
        },
        checkMultipleSelectedLogic(array, logicName) {
            // for use with subtype, type, class
            // if array has less than 2 elements, change logic to 'OR' to avoid including logic param 'AND' needlessly
            if (array.length < 2) {
                this.form[logicName] = 'OR'
            }
        },
        updateSearch: debounce( function() {
            this.searchComments = TextService.getSearchComments({
                query: this.form,
                option: this.$searchOptions
            })
            this.queryString = TextService.convertToURLParams(this.form);
            this.query = TextService.filterQueryObject(this.form)
        }, 200),
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
        showNumericalInputField(operatorText) {
            if (!operatorText) return true 

            const operator = this.option.statOperator.filter(s => { return operatorText && s.text == operatorText })[0]
            
            return operator.input === undefined ? true : operator.input
        },
        checkStatOperatorAvailable(stat) {
            // check if the updated stat at statINdex has the currently selected operator ('= X' specifically),
            // if the selected operator does not exist for that stat, clear that operator
            
            const operator = this.option.statOperator.filter(s => { return s.for && s.for.indexOf(stat.name) > -1 })[0]

            if (operator === undefined) {
                stat.operator = null;
                stat.operatorSymbol = null;
                stat.operatorText = null;
                stat.value = null;
            }
        },
        getStatQuery: TextService.getStatQuery,
    },
    computed: {
        statValueIsInteger() {
            if (this.stat.value === null) return null
            return Number.isInteger(Number(this.stat.value))
        },
        firstStatAllFilled() {
            return this.checkStat(this.stat)
        },
        // showNumericalInputField() {
        //     if (!this.stat.operatorText) return true 

        //     const operator = this.option.statOperator.filter(s => { return this.stat.operatorText && s.text == this.stat.operatorText })[0]
        //     console.log(operator)
        //     return operator.input === undefined ? true : operator.input
        // },
        apiUrl() {
            return `https://api.gatcg.com/cards/search?${this.queryString}`
        }
    },
    created () {
        if (this.$searchOptions) {
            this.init()
            return
        }

        // for some reason, listening to global event only work with this in certain NODE_ENV
        if (process.env.NODE_ENV === 'development') {
            this.$eventHub.$on('apiloadcomplete', this.init());
        } else {
            // must use arrow function for anything else including production 
            this.$eventHub.$on('apiloadcomplete', () => {
                this.init()
            });
        }
    },
};

</script>

<template>
	<div class="page">
		<b-container
            v-if="option"
            class="page__advanced-search"
        >
            <b-row>
                <slide-y-up-transition appear :duration=500 mode="out-in">
                    <b-col>
                        <h4>Advanced Search</h4>
                    </b-col>
                </slide-y-up-transition>
            </b-row>
            <slide-x-left-transition appear :duration=500 mode="out-in">
                <b-row>
                    <b-col
                        lg="4"
                        md="12"
                        order="2"
                        order-md="2"
                        class="mt-3 mt-lg-0"
                    >
                        <div
                            class="sticky-top"
                            style="z-index: 0"
                        >   
                            <b-list-group v-if="searchComments.length">
                                <b-list-group-item
                                    class="flex-column"
                                >
                                    <h5 class="mb-1">
                                        <b-icon-text-paragraph class="mr-1" />
                                        Search Summary
                                    </h5>
                                    <p  class="mb-0">
                                        Looking for cards where...
                                    </p>
                                    <ul style="list-style-type: square; padding-left: 20px" >
                                        <li
                                            v-for="(comment, index) in searchComments"
                                            :key=index
                                            class="animate__animated animate__fadeIn"
                                        >
                                            {{ comment.text }}
                                        </li>
                                    </ul>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <h5 class="mb-1">
                                        <b-icon-arrow-left-right scale="0.8" class="mr-1" />
                                        API query
                                    </h5>
                                    <p class="mb-0">
                                    Use with the API to perform this search
                                    </p>
                                    <b-input-group prepend="" class="mt-3">
                                        <b-form-input
                                            :value="apiUrl"
                                            disabled
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                @click="copyText(apiUrl)"
                                                content="Copied to clipboard" 
                                                v-tippy="{
                                                    ...tippyConfig,
                                                    trigger : 'click'
                                                }"
                                            >
                                                <b-icon-clipboard scale="0.85" />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-col>
                    <b-col
                        lg="8"
                        md="12"
                        order="1"
                        order-md="1"
                    >
                        <b-card class="border-secondary">
                            <b-form
                                @submit.stop.prevent="() => {}"
                            >
                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Card Name"
                                    label-for="name-input"
                                >
                                    <b-form-input
                                        id="name-input"
                                        v-model="form.name"
                                        type="text"
                                        placeholder="Any word in the name"
                                        @input="updateSearch"
                                        @keyup.enter="onInputKeyupEnter"
                                    />
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Effect"
                                    label-for="effect-input"
                                >
                                    <b-form-input
                                        id="effect-input"
                                        v-model="form.effect"
                                        type="text"
                                        placeholder="Any word in the effect box"
                                        @input="updateSearch"
                                        @keyup.enter="onInputKeyupEnter"
                                    />
                                </b-form-group>
                                
                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"
                                    :state="false"
                                    label="Stat"
                                >

                                    <!-- this appears only when there are entered stat -->
                                    <b-input-group
                                        v-for="(stat, index) in stats"
                                        :key=index
                                        class="mb-2"
                                    >
                                        <template #prepend>
                                            <b-dropdown
                                                class="stat-dropdown"
                                                :text="stat.nameText || 'Stat Name'"
                                            >
                                                <b-dropdown-item
                                                    v-for="(item, index) in option.stat"
                                                    :key=index
                                                    @click="stat.name = item.value; stat.nameText = item.text; checkStatOperatorAvailable(stat); syncStat()"
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                            <b-dropdown
                                                class="stat-dropdown"
                                                :text="stat.operatorSymbol || 'Operator '"
                                            >
                                            
                                                <b-dropdown-item
                                                    v-for="(item, index) in option.statOperator"
                                                    :key=index
                                                    @click="stat.operator = item.value; stat.operatorText = item.text; stat.operatorSymbol = item.symbol; syncStat()"
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                        </template>
                                        
                                        <b-form-input
                                            v-if="showNumericalInputField(stat.operatorText)"
                                            v-model="stat.value"
                                            type="number"
                                            number
                                            placeholder="Integer"
                                            :state="validateInteger(stat.value, 'errorOnly')"
                                            @input="syncStat()"
                                            @keyup.enter.stop.prevent="() => {}"
                                        />
                                    
                                        <template #append>
                                            <b-button
                                                @click="deleteStat(index)"
                                            >
                                                <b-icon icon="x" />
                                            </b-button>
                                        </template>
                                    </b-input-group>

                                    <!-- this stat form should always appear -->
                                    <b-input-group>
                                        <template #prepend>
                                            <b-dropdown
                                                class="stat-dropdown"
                                                :text="stat.nameText || 'Stat Name'"
                                            >
                                                <b-dropdown-item
                                                    v-for="(item, index) in option.stat"
                                                    :key=index
                                                    @click="stat.name = item.value; stat.nameText = item.text; checkAddFirstStat()"
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                            <b-dropdown
                                                class="stat-dropdown"
                                                :text="stat.operatorSymbol || 'Operator '"
                                            >
                                                <template
                                                    v-for="(item, index) in option.statOperator"
                                                >
                                                    <b-dropdown-item
                                                        v-if="item.for === undefined || item.for.indexOf(stat.name) > -1"
                                                        :key=index
                                                        @click="stat.operator = item.value; stat.operatorText = item.text; stat.operatorSymbol = item.symbol; checkAddFirstStat()"
                                                    >
                                                        {{ item.text }}
                                                    </b-dropdown-item>
                                                    <!-- <b-dropdown-item
                                                        v-if="item.for && item.for.indexOf[stat.name] > -1"
                                                        :key=index
                                                        @click="stat.operator = item.value; stat.operatorText = item.text; stat.operatorSymbol = item.symbol; checkAddFirstStat()"
                                                    >
                                                        {{ item.text }}
                                                    </b-dropdown-item> -->
                                                </template>
                                                
                                                <!-- <b-dropdown-item
                                                    v-for="(item, index) in option.statOperator"
                                                    :key=index
                                                    @click="stat.operator = item.value; stat.operatorText = item.text; stat.operatorSymbol = item.symbol; checkAddFirstStat()"
                                                    
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item> -->
                                                
                                            </b-dropdown>
                                        </template>
                                        
                                        <b-form-input
                                            v-if="showNumericalInputField(stat.operatorText)"
                                            v-model="stat.value"
                                            type="number"
                                            number
                                            placeholder="Integer"
                                            :state="validateInteger(stat.value)"
                                            @blur="checkAddFirstStat()"
                                            @keyup.enter="checkAddFirstStat() ? () => {} : onInputKeyupEnter($event) "
                                        />
                                    
                                        <template
                                            #append
                                            v-if="firstStatAllFilled"
                                        >
                                            <b-button>
                                                <b-icon icon="check2" />
                                            </b-button>
                                        </template>
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"
                                    label="Element"
                                    label-for="element-input"
                                >
                                    <b-row>
                                        <b-col
                                            cols="12"
                                        >
                                            <TagDropdown
                                                v-model="form.element"
                                                :available-options="option.element"
                                                @input="updateSearch()"
                                            />
                                        </b-col>
                                        <!-- <b-col
                                            cols="12"
                                            class="pt-2 flow-column"
                                        >
                                            <b-form-radio-group
                                                id="element-input"
                                                class="element-input"
                                                v-model="form.element"
                                                stacked
                                                @input="updateSearch"
                                            >
                                                <b-form-radio
                                                    v-for="(optionItem, index) in option.element"
                                                    :key=index
                                                    :value="optionItem.value"
                                                    class="pb-1"
                                                >
                                                    {{ optionItem.text }}
                                                </b-form-radio>
                                                <b-form-radio
                                                    :disabled="!form.element"
                                                    @click="form.element = null"
                                                    :value="null"
                                                >
                                                    Not Selected
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col> -->
                                    </b-row>
                                    
                                    
                                </b-form-group>


                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Type"
                                    label-for="type-input"
                                >
                                    <b-row>
                                        <b-col
                                            cols="12"
                                        >
                                            <TagDropdown
                                                v-model="form.type"
                                                :available-options="option.type"
                                                @input="updateSearch(), checkMultipleSelectedLogic(form.type, 'type_logic')"
                                            />
                                        </b-col>
                                        <b-col
                                            v-if="form.type.length > 1"
                                            cols="12"
                                        >
                                            <b-form-radio-group
                                                v-model="form.type_logic"
                                                @input="updateSearch"
                                                class=""
                                                buttons
                                            >
                                                
                                                    <b-form-radio
                                                        value="OR"
                                                        class="px-3 py-1"
                                                    >
                                                        <div
                                                            :content='`<span>Match cards with <strong>any one</strong> of the selected type.</span>`'
                                                            v-tippy="{
                                                                arrow: true,
                                                                placement: 'bottom-start',
                                                                theme: 'grey',
                                                            }"
                                                        >
                                                            <b-icon
                                                                :icon="form.type_logic === 'OR' ? 'check-square' : 'square'"
                                                                font-scale="1"
                                                                shift-v="-1"
                                                                class="mr-2"
                                                            />
                                                            <span class="option-title">Any type</span>
                                                        </div>
                                                    </b-form-radio>
                                                <span
                                                    
                                                >
                                                </span>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-3 py-1"
                                                >
                                                    <div
                                                        :content='`<span>Match cards with <strong>all</strong> of the selected type.</span>`'
                                                        v-tippy="{
                                                            arrow: true,
                                                            placement: 'bottom-start',
                                                            theme: 'grey',
                                                        }"
                                                    >
                                                        <b-icon
                                                            :icon="form.type_logic === 'AND' ? 'check-square' : 'square'"
                                                            font-scale="1"
                                                            shift-v="-1"
                                                            class="mr-2"
                                                        />
                                                        <span class="option-title">All type</span>
                                                    </div>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col>
                                        <!-- <b-col
                                            cols="12"
                                            class="pt-2 flow-column"
                                        >
                                            <b-form-checkbox-group
                                                id="type-input"
                                                v-model="form.type"
                                                stacked
                                                switches
                                                @input="updateSearch"
                                            >
                                                <b-form-checkbox
                                                    v-for="(optionItem, index) in option.type"
                                                    :key=index
                                                    :value="optionItem.value"
                                                    class="pb-1"
                                                >
                                                    {{ optionItem.text }}
                                                </b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            class="mt-2"
                                        >
                                            <b-form-radio-group
                                                v-model="form.type_logic"
                                                stacked
                                                @input="updateSearch"
                                                class="mx-4"
                                            >
                                                <b-form-radio
                                                    value="OR"
                                                    class="px-1 pb-1"
                                                >
                                                    <div class="option-title">Any</div>
                                                    <span>Match cards with <strong>any one</strong> of the selected type.</span>
                                                </b-form-radio>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-1"
                                                >
                                                    <div class="option-title">All</div>
                                                    <span>Match cards with <strong>all</strong> of the selected type.</span>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col> -->
                                    </b-row>
                                    
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Subtype"
                                    label-for="subtype-input"
                                >
                                    <b-row>
                                        
                                        <!-- <b-col
                                            cols="12"
                                            class="mt-2"
                                        >
                                            <b-form-radio-group
                                                v-model="form.subtype_logic"
                                                stacked
                                                @input="updateSearch"
                                                class="mx-4"
                                            >
                                                <b-form-radio
                                                    value="OR"
                                                    class="px-1 pb-1"
                                                >
                                                    <div class="option-title">Any</div>
                                                    <span>Match cards with <strong>any one</strong> of the selected subtype.</span>
                                                </b-form-radio>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-1"
                                                >
                                                    <div class="option-title">All</div>
                                                    <span>Match cards with <strong>all</strong> of the selected subtype.</span>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col> -->
                                        <b-col
                                            cols="12"
                                        >
                                            <TagDropdown
                                                v-model="form.subtype"
                                                :available-options="option.subtype"
                                                @input="updateSearch(), checkMultipleSelectedLogic(form.subtype, 'subtype_logic')"
                                            />
                                        </b-col>
                                        <b-col
                                            v-if="form.subtype.length > 1"
                                            cols="12"
                                        >
                                            <b-form-radio-group
                                                v-model="form.subtype_logic"
                                                @input="updateSearch"
                                                class=""
                                                buttons
                                            >
                                                
                                                    <b-form-radio
                                                        value="OR"
                                                        class="px-3 py-1"
                                                        
                                                    >
                                                        <div
                                                            :content='`<span>Match cards with <strong>any one</strong> of the selected subtype.</span>`'
                                                            v-tippy="{
                                                                arrow: true,
                                                                placement: 'bottom-start',
                                                                theme: 'grey',
                                                            }"
                                                        >
                                                            <b-icon
                                                                :icon="form.subtype_logic === 'OR' ? 'check-square' : 'square'"
                                                                font-scale="1"
                                                                shift-v="-1"
                                                                class="mr-2"
                                                            />
                                                            <span class="option-title">Any subtype</span>
                                                        </div>
                                                        <!-- <span>Match cards with <strong>any one</strong> of the selected subtype.</span> -->
                                                    </b-form-radio>
                                                <span
                                                    
                                                >
                                                </span>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-3 py-1"
                                                >
                                                    <div
                                                        :content='`<span>Match cards with <strong>all</strong> of the selected subtype.</span>`'
                                                        v-tippy="{
                                                            arrow: true,
                                                            placement: 'bottom-start',
                                                            theme: 'grey',
                                                        }"
                                                    >
                                                        <b-icon
                                                            :icon="form.subtype_logic === 'AND' ? 'check-square' : 'square'"
                                                            font-scale="1"
                                                            shift-v="-1"
                                                            class="mr-2"
                                                        />
                                                        <span class="option-title">All subtype</span>
                                                    </div>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col>
                                        <!-- <b-col
                                            cols="12"
                                            class="pt-2 flow-column"
                                        >
                                            <b-form-checkbox-group
                                                id="subtype-input"
                                                v-model="form.subtype"
                                                stacked
                                                switches
                                                @input="updateSearch"
                                            >
                                                <b-form-checkbox
                                                    v-for="(optionItem, index) in option.subtype"
                                                    :key=index
                                                    :value="optionItem.value"
                                                    class="pb-1"
                                                >
                                                    {{ optionItem.text }}
                                                </b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-col> -->
                                    </b-row>
                                    
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Class"
                                    label-for="class-input"
                                >
                                    <b-row>
                                        <b-col
                                            cols="12"
                                        >
                                            <TagDropdown
                                                v-model="form.class"
                                                :available-options="option.class"
                                                @input="updateSearch(), checkMultipleSelectedLogic(form.class, 'class_logic')"
                                            />
                                        </b-col>
                                        <b-col
                                            cols="12"
                                            v-if="form.class.length > 1"
                                        >
                                            <b-form-radio-group
                                                v-model="form.class_logic"
                                                @input="updateSearch"
                                                class=""
                                                buttons
                                            >
                                                
                                                    <b-form-radio
                                                        value="OR"
                                                        class="px-3 py-1"
                                                    >
                                                        <div
                                                            :content='`<span>Match cards with <strong>any one</strong> of the selected class.</span>`'
                                                            v-tippy="{
                                                                arrow: true,
                                                                placement: 'bottom-start',
                                                                theme: 'grey',
                                                            }"
                                                        >
                                                            <b-icon
                                                                :icon="form.class_logic === 'OR' ? 'check-square' : 'square'"
                                                                font-scale="1"
                                                                shift-v="-1"
                                                                class="mr-2"
                                                            />
                                                            <span class="option-title">Any class</span>
                                                        </div>
                                                    </b-form-radio>
                                                <span
                                                    
                                                >
                                                </span>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-3 py-1"
                                                >
                                                    <div
                                                        :content='`<span>Match cards with <strong>all</strong> of the selected class.</span>`'
                                                        v-tippy="{
                                                            arrow: true,
                                                            placement: 'bottom-start',
                                                            theme: 'grey',
                                                        }"
                                                    >
                                                        <b-icon
                                                            :icon="form.class_logic === 'AND' ? 'check-square' : 'square'"
                                                            font-scale="1"
                                                            shift-v="-1"
                                                            class="mr-2"
                                                        />
                                                        <span class="option-title">All class</span>
                                                    </div>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col>
                                    </b-row>
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Rarity"
                                >
                                    <b-row>
                                        <b-col
                                            cols="12"
                                        >
                                            <TagDropdown
                                                v-model="form.rarity"
                                                :available-options="option.rarity"
                                                @input="updateSearch()"
                                            />
                                        </b-col>
                                        <!-- <b-col
                                            cols="12"
                                            v-if="form.class.length > 1"
                                        >
                                            <b-form-radio-group
                                                v-model="form.class_logic"
                                                @input="updateSearch"
                                                class=""
                                                buttons
                                            >
                                                
                                                    <b-form-radio
                                                        value="OR"
                                                        class="px-3 py-1"
                                                    >
                                                        <div
                                                            :content='`<span>Match cards with <strong>any one</strong> of the selected class.</span>`'
                                                            v-tippy="{
                                                                arrow: true,
                                                                placement: 'bottom-start',
                                                                theme: 'grey',
                                                            }"
                                                        >
                                                            <b-icon
                                                                :icon="form.class_logic === 'OR' ? 'check-square' : 'square'"
                                                                font-scale="1"
                                                                shift-v="-1"
                                                                class="mr-2"
                                                            />
                                                            <span class="option-title">Any class</span>
                                                        </div>
                                                    </b-form-radio>
                                                <span
                                                    
                                                >
                                                </span>
                                                <b-form-radio
                                                    value="AND"
                                                    class="px-3 py-1"
                                                >
                                                    <div
                                                        :content='`<span>Match cards with <strong>all</strong> of the selected class.</span>`'
                                                        v-tippy="{
                                                            arrow: true,
                                                            placement: 'bottom-start',
                                                            theme: 'grey',
                                                        }"
                                                    >
                                                        <b-icon
                                                            :icon="form.class_logic === 'AND' ? 'check-square' : 'square'"
                                                            font-scale="1"
                                                            shift-v="-1"
                                                            class="mr-2"
                                                        />
                                                        <span class="option-title">All class</span>
                                                    </div>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-col> -->
                                    </b-row>
                                </b-form-group>
                            
                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Flavor"
                                    label-for="flavor-input"
                                >
                                    <b-form-input
                                        id="flavor-input"
                                        v-model="form.flavor"
                                        type="text"
                                        placeholder="Any word in the flavor text"
                                        @input="updateSearch"
                                        @keyup.enter="onInputKeyupEnter"
                                    />
                                </b-form-group>
                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Illustrator"
                                    label-for="illustrator-input"
                                >
                                    <b-form-input
                                        id="illustrator-input"
                                        v-model="form.illustrator"
                                        type="text"
                                        placeholder="Any word in the illustrator's name"
                                        @input="updateSearch"
                                        @keyup.enter="onInputKeyupEnter"
                                    />
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Set"
                                >
                                    <b-input-group
                                        block
                                    >
                                        <template #prepend>
                                            <b-dropdown
                                                class="set-dropdown"
                                                :text="form.prefix ? option.set.filter(set => set.value === form.prefix)[0].text : 'Select set'"
                                            >
                                                <b-dropdown-item
                                                    v-for="(item, index) in option.set"
                                                    :key=index
                                                    @click="form.prefix = item.value, updateSearch()"
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    key="clear"
                                                    @click="form.prefix = null, updateSearch()"
                                                >
                                                    Clear
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                        </template>
                                            <b-form-input
                                                v-model="form.collector_number"
                                                type="text"
                                                placeholder="search collect number e.g. '001'"
                                                @input="updateSearch"
                                                @keyup.enter="onInputKeyupEnter"
                                            />
                                        
                                    </b-input-group>
                                
                                </b-form-group>

                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                    label="Restriction"
                                >
                                    <b-input-group
                                        block
                                    >
                                        
                                        <template #prepend>
                                            <b-dropdown
                                                class="legality-format-dropdown"
                                                :text="form.legality_format ? option.gameFormat.filter(format => format.value === form.legality_format)[0].text : 'Format '"
                                            >
                                                <b-dropdown-item
                                                    v-for="(item, index) in option.gameFormat"
                                                    :key=index
                                                    @click="form.legality_format = item.value, updateSearch()"
                                                >
                                                    {{ item.text }}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    key="clear"
                                                    @click="form.legality_format = null, form.legality_limit = null, updateSearch()"
                                                >
                                                    Clear
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                            <b-dropdown
                                                class="legality-limit-dropdown"
                                                :text="
                                                    form.legality_limit !== null ? 
                                                        form.legality_limit === 0 ? 'Banned ' : 'Limited '
                                                        : 'Restriction '
                                                "
                                            >
                                                <b-dropdown-item
                                                    @click="form.legality_limit = 0, updateSearch()"
                                                >
                                                    Banned
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="form.legality_limit = 1, updateSearch()"
                                                >
                                                    Limited
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    key="clear"
                                                    @click="form.legality_limit = null, form.legality_format = null, updateSearch()"
                                                >
                                                    Clear
                                                </b-dropdown-item>
                                                
                                            </b-dropdown>
                                        </template>
                                        <!-- jack here -->
                                        <b-form-input
                                            v-model="form.legality_limit"
                                            type="number"
                                            number
                                            placeholder="Amount"
                                            :state="validateInteger(stat.value, 'errorOnly')"
                                            @input="updateSearch"
                                            @keyup.enter="onInputKeyupEnter"
                                        />
                                        
                                        
                                    </b-input-group>
                                
                                </b-form-group>


                                <b-form-group
                                    label-class="pr-2"
                                    label-cols="12"
                                    label-cols-lg="3"
                                    label-align-lg="right"

                                    content-cols="12"
                                    content-cols-lg="9"

                                >
                                    <b-button
                                        block
                                        class="text-left"
                                        @click="search"
                                    >
                                        <b-icon
                                            icon="search"
                                            font-scale="0.9"
                                            shift-v="2"
                                            class="mr-2"
                                        />
                                        Search
                                    </b-button>
                                </b-form-group>
                                
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </slide-x-left-transition>
		</b-container>
	</div>
</template>


<style lang="scss">
    .stat-dropdown .dropdown-toggle::after,
    .set-dropdown .dropdown-toggle::after,
    .legality-limit-dropdown .dropdown-toggle::after,
    .legality-format-dropdown .dropdown-toggle::after {
        margin-left: 1em;
    }

    .set-dropdown .dropdown-toggle {
        text-align: center;
    }

    .element-input {
        width: 100%;
        .btn {
            padding-left: 1em;
            text-align: left;
        };
    }

    .flow-column {
        column-count: 2;
        column-fill: balance-all;
    }

    .option-title {
        font-size: 0.85rem;
    }

</style>